import { v4 as uuidv4 } from 'uuid';

import DaifugoRules from './gamerules';
import Player from './player';
import Table from './table';
import Card from './card';

enum GamePhase {
  WAITING_FOR_PLAYERS = 'WAITING_FOR_PLAYERS',
  PLAY = 'PLAY',
  GIVE = 'GIVE',
  DISCARD = 'DISCARD',
  OVER = 'OVER',
}

interface GameOptions {
  player1: Player;
  player2?: Player;
  player3?: Player;
  player4?: Player;
  table?: Table;

  gameRules?: DaifugoRules;

  currentPlayerUID?: string;
  gamePhase?: GamePhase;
  revolution?: number;
  reverse?: boolean;
  skipCount?: number;
  finishedRound?: [string, string, string, string];
}

class Game {
  private _player1: Player;
  private _player2?: Player; // TODO: may be undefined (wait for join etc). then grey out cards in ui
  private _player3?: Player;
  private _player4?: Player;
  private _table: Table;

  private _gameRules: DaifugoRules;

  private _currentPlayerUid?: string;
  private _gamePhase: GamePhase;
  private _revolution: number; // increase count for (re)*volution
  private _reverse: boolean;
  private _skipCount: number;
  private _finishedRound: [string, string, string, string];

  static fromObject(obj: any): Game {
    return new Game({
      player1: Player.fromObject(obj._player1),
      player2: obj._player2 ? Player.fromObject(obj._player2) : undefined,
      player3: obj._player3 ? Player.fromObject(obj._player3) : undefined,
      player4: obj._player4 ? Player.fromObject(obj._player4) : undefined,
      table: Table.fromObject(obj._table),
      gameRules: DaifugoRules.fromObject(obj._gameRules),
      currentPlayerUID: obj._currentPlayerUid,
      gamePhase: obj._gamePhase,
      revolution: obj._revolution,
      reverse: obj._reverse,
      skipCount: obj._skipCount,
      finishedRound: obj._finishedRound,
    });
  }

  constructor(options: GameOptions) {
    this._player1 = options.player1;
    this._player2 = options.player2;
    this._player3 = options.player3;
    this._player4 = options.player4;
    this._table = options.table || new Table({ cards: [] });

    this._gameRules = options.gameRules || DaifugoRules.default();

    this._currentPlayerUid = options.currentPlayerUID;
    if (this._currentPlayerUid) {
      if (!this.playerByUid(this._currentPlayerUid)) {
        throw new Error(`Player with uid ${this._currentPlayerUid} not found`);
      }
      this.currentPlayer!.isPlayersTurn = true;
    }
    this._gamePhase = options.gamePhase || GamePhase.WAITING_FOR_PLAYERS;
    this._revolution = options.revolution || 0;
    this._reverse = options.reverse || false;
    this._skipCount = options.skipCount || 0;
    this._finishedRound = options.finishedRound || ['', '', '', ''];
  }

  maskedGameStateObject(playerUid: string): object {

    const playerObject = (p: Player | undefined) => {
      if (!p) {
        return undefined;
      }

      return {
        _uid: p.uid,
        _name: p.name,
        _playable: p.uid === playerUid ? true : false,
        _score: p.score,
        _hand: p.uid === playerUid ? p.hand : p.hand.map(() => -1),
        _selectedCards: p.uid === playerUid ? p.selectedCards : [],
        _isPlayersTurn: p.isPlayersTurn,
      };
    };

    const p1 = this.playerByUid(playerUid);
    if (!p1) {
      throw new Error(`Player with uid ${playerUid} not found`);
    }
    const p2 = this.nextPlayer(p1);
    const p3 = p2 ? this.nextPlayer(p2) : undefined;
    const p4 = p3 ? this.nextPlayer(p3) : undefined;

    return {
      _player1: playerObject(p1),
      _player2: playerObject(p2),
      _player3: playerObject(p3),
      _player4: playerObject(p4),
      _table: this.table,
      _gameRules: this.gameRules,
      _currentPlayerUid: this.currentPlayerUid,
      _gamePhase: this.gamePhase,
      _reverse: this._reverse,
      _skipCount: this._skipCount,
      _finishedRound: this._finishedRound,
    };
  }

  get gamePhase(): GamePhase {
    return this._gamePhase;
  }

  get gameOver(): boolean {
    return this._gamePhase === GamePhase.OVER;
  }

  get revolution(): number {
    return this._revolution;
  }

  get gameRules(): DaifugoRules {
    return this._gameRules;
  }

  generateDeck(): number[] {
    const deck: number[] = [];
    for (let i = 0; i < 54; i++) {
      deck.push(i);
    }
    return deck;
  }

  shuffle(deck: number[]): number[] {
    for (let i = deck.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [deck[i], deck[j]] = [deck[j], deck[i]];
    }
    return deck;
  }

  get player1(): Player {
    return this._player1;
  }

  get player2(): Player | undefined {
    return this._player2;
  }

  get player3(): Player | undefined {
    return this._player3;
  }

  get player4(): Player | undefined {
    return this._player4;
  }

  get players(): Player[] | undefined {
    if (this._player2 === undefined || this._player3 === undefined || this._player4 === undefined) {
      return undefined;
    } else {
      return [this._player1, this._player2, this._player3, this._player4];
    }
  }


  addPlayer(player: Player): void {
    if (this._player2 === undefined) {
      this._player2 = player;
    } else if (this._player3 === undefined) {
      this._player3 = player;
    } else if (this._player4 === undefined) {
      this._player4 = player;
    } else {
      throw new Error('Cannot add more than 4 players');
    }
  }

  get currentPlayerUid(): string | undefined {
    return this._currentPlayerUid;
  }

  playerByUid(uid: string): Player | undefined {
    if (uid === this._player1.uid) {
      return this._player1;
    } else if (uid === this._player2?.uid) {
      return this._player2;
    } else if (uid === this._player3?.uid) {
      return this._player3;
    } else if (uid === this._player4?.uid) {
      return this._player4;
    } else {
      return undefined;
    }
  }

  get currentPlayer(): Player | undefined {
    if (this._currentPlayerUid) {
      return this.playerByUid(this._currentPlayerUid);
    } else {
      return undefined;
    }
  }

  private set currentPlayer(player: Player | undefined) {
    this._currentPlayerUid = player?.uid;
  }

  get table(): Table {
    return this._table;
  }

  get reverse(): boolean {
    return this._reverse;
  }

  get skipCount(): number {
    return this._skipCount;
  }

  get finishedRound(): [string, string, string, string] {
    return this._finishedRound;
  }

  deal() {
    if (this._player2 === undefined || this._player3 === undefined || this._player4 === undefined) {
      throw new Error('Not enough players to start game');
    }

    const deck = this.shuffle(this.generateDeck());
    this._player1.hand = deck.slice(0, 13);
    this._player2.hand = deck.slice(13, 26);
    this._player3.hand = deck.slice(26, 40); // TODO: P3 and P4 always get 14 cards
    this._player4.hand = deck.slice(40, 54);

    this._table = new Table({ cards: [] });

    this._player1.isPlayersTurn = true;
    this.currentPlayer = this._player1;

    this._gamePhase = GamePhase.PLAY;
  }

  playCards(playerUid: string, cards: number[] = []): boolean {
    if (this._gamePhase !== GamePhase.PLAY) {
      console.error('Not in play phase');
      return false;
    }

    const player = this.playerByUid(playerUid);

    if (!player) {
      console.error(`Player with uid ${playerUid} not found`);
      return false;
    }

    const skip = cards.length === 0;

    const tableRank = this.table.currentRank;

    if (skip) {
      if (this._table.isEmpty()) {
        console.error('Cannot skip first turn');
        return false;
      }

      this._skipCount++;

      this._table.skip(player.uid);

      if (this._skipCount === 3) {
        this._table = new Table({ cards: [] });
        this._skipCount = 0;
      }
    } else {
      if (!this.gameRules.isMoveLegal(this, player, cards)) {
        return false;
      }

      this._skipCount = 0;

      player.hand = player.hand.filter(card => !cards.includes(card));
      this._table.push(player.uid, ...cards);

      if (this._table.playedTogether === undefined) {
        this._table.playedTogether = cards.length;
      }
    }

    console.log(`Player ${player.name} played ${cards.length} cards`);

    this.nextState(tableRank, player, cards);

    return true;
  }

  private nextState(prevRank: number, player: Player, cards: number[]) {

    var playedRank = cards.length === 0 ? 0 : Card.getRank(cards.every(card => Card.getSuit(card) === 'JOKER') ? 52 : cards[0]);
    if (playedRank === 16 && this.table.isTightRank) {
      playedRank = this.table.tightRank + 1;
    }

    if (this.gameRules.n7give && playedRank === 7) {
      if (player.hand.length !== 0) {
        this._gamePhase = GamePhase.GIVE;
        return;
      }
    }

    if (this.gameRules.n8clear && playedRank === 8) {
      this._table = new Table({ cards: [] });
      if (player.hand.length !== 0) {
        return;
      }
    }

    if (this.gameRules.n9reverse && playedRank === 9) {
      this._reverse = !this._reverse;
    }

    if (this.gameRules.n10burn && playedRank === 10) {
      if (player.hand.length !== 0) {
        this._gamePhase = GamePhase.DISCARD;
        return;
      }
    }

    if (this.gameRules.n11back && playedRank === 11) {
      this.table.backward = !this.table.backward;
    }

    if (this.gameRules.tightRank) {
      var adjacentRank = prevRank + ((this.revolution % 2 === 1) !== this.table.backward ? -1 : 1);
      if (playedRank === adjacentRank) {
        this.table.tightRank = playedRank;
        console.log('Tight rank!');
      }
    }

    if (this.gameRules.tightSuits) {
      const prevTopCards = this.table.cards.slice(- this.table.playedTogether! * 2, - this.table.playedTogether!);
      var tableSuits = prevTopCards.filter(card => card >= 0).map(card => Card.getSuit(card));
      var tightSuits = this.table.tightSuits;
      for (var card of cards) { // find additional tight suits
        var suit = Card.getSuit(card);
        if (suit !== 'JOKER' && tableSuits.includes(suit) && !tightSuits.includes(suit)) {
          tightSuits.push(suit);
          console.log('New tight suit:', suit);
        }
      }
      this.table.tightSuits = tightSuits;
    }

    this.currentPlayer!.isPlayersTurn = false;
    var n5skips = this.gameRules.n5skip && cards.find(card => Card.getRank(card) === 5) ? cards.length : 0;
    for (let i = 0; i < 4; i++) {
      if (this.reverse) {
        this.currentPlayer = this.prevPlayer(this.currentPlayer!);
      } else {
      this.currentPlayer = this.nextPlayer(this.currentPlayer!);
      }
      if (!this.finishedRound.includes(this.currentPlayer!.uid)) {
        if (n5skips === 0) {
        this.currentPlayer!.isPlayersTurn = true;
        break;
      }
        n5skips--;
      }
      
      this._skipCount++;
      this._table.skip(player.uid);
      if (this._skipCount === 3) {
        this._table = new Table({ cards: [] });
        this._skipCount = 0;
      }
    }

    // FINISH HAND / GAME
    
    if (player.hand.length === 0) {
      for (let i = 0; i < 4; i++) {
        if (this._finishedRound[i] === '') {
          this._finishedRound[i] = player.uid;
          break;
        }
      }
    }

    if (this._finishedRound.filter(p => p !== '').length === 3) {
      this._gamePhase = GamePhase.OVER;
      var playerUids = new Array(this._player1.uid, this._player2!.uid, this._player3!.uid, this._player4!.uid);
      this._finishedRound[3] = playerUids.filter(p => !this._finishedRound.includes(p))[0];
    }

  }


  nextPlayer(player: Player): Player | undefined {
    if (player === this._player1) {
      return this._player2;
    } else if (player === this._player2) {
      return this._player3;
    } else if (player === this._player3) {
      return this._player4;
    } else {
      return this._player1;
    }
  }

  prevPlayer(player: Player): Player | undefined {
    if (player === this._player1) {
      return this._player4;
    } else if (player === this._player2) {
      return this._player1;
    } else if (player === this._player3) {
      return this._player2;
    } else {
      return this._player3;
    }
  }

}

export default Game;