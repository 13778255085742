import { io } from 'socket.io-client';

const socket = io(process.env.REACT_APP_BACKEND_URL || 'http://localhost:3001');

if (!process.env.REACT_APP_BACKEND_URL) {
  console.warn('REACT_APP_BACKEND_URL not set, using default value');
}
else {
  console.log('REACT_APP_BACKEND_URL:', process.env.REACT_APP_BACKEND_URL);
}

export default socket;