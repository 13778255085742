enum Suit {
  DIAMONDS = 'DIAMONDS',
  HEARTS = 'HEARTS',
  SPADES = 'SPADES',
  CLUBS = 'CLUBS',
  JOKER = 'JOKER',
  NONE = 'NONE'
}

class Card {
  static getSuit(card: number): Suit {
    if (card < 0)
      return Suit.NONE;
    if (card < 13)
      return Suit.DIAMONDS;
    else if (card < 26)
      return Suit.HEARTS;
    else if (card < 39)
      return Suit.SPADES;
    else if (card < 52)
      return Suit.CLUBS;
    else
      return Suit.JOKER;
  }

  static getSuitChar(card: number): string {
    if (card < 0)
      return '-';
    else
    if (card < 13)
      return '♦';
    else if (card < 26)
      return '♥';
    else if (card < 39)
      return '♠';
    else if (card < 52)
      return '♣';
    else
      return '⍟';
  }

  static getRank(card: number): number {
    if (card < 0)
      return 0;
    if (card < 52)
      if (card % 13 < 2)
        return card % 13 + 1 + 13; // Ace, 2
      else
        return card % 13 + 1;
    else
      return 16; // Joker
  }

  static order(card1: number, card2: number): number {
    if (Card.getRank(card1) < Card.getRank(card2))
      return -1;
    else if (Card.getRank(card1) > Card.getRank(card2))
      return 1;
    // else if (Card.getSuit(card1) < Card.getSuit(card2))
    //   return -1;
    // else if (Card.getSuit(card1) > Card.getSuit(card2))
    //   return 1;
    else
      return 0;
  }
}

export { Card, Suit };
export default Card;