import Game from './game';
import Player from './player';
import Table from './table';
import Card from './card';

class GameRules {
  isPlayersTurn(game: Game, player: Player): boolean {
    if (!player.isPlayersTurn) {
      console.error(player.name, 'not player', game.currentPlayer!.name, '\'s turn');
      return false;
    }
    return true;
  }

  canPlayCards(game: Game, player: Player, cards: number[]): boolean {
    if (cards.length === 0 && !game.table.isEmpty()) {
      console.error('Cannot skip first turn');
      return false;
    }
    if (!cards.every(card => 0 <= card && card < 54)) {
      console.error('Invalid card(s) played');
      return false;
    }
    if (game.table.playedTogether && game.table.playedTogether !== cards.length) {
      console.error('Number of cards played does not match current table');
      return false;
    }
    if (!cards.every(card => player.hand.includes(card))) {
      console.error('Player does not have all cards');
      return false;
    }
    return true;
  }

  isMoveLegal(game: Game, player: Player, cards: number[]): boolean {
    return this.isPlayersTurn(game, player) && this.canPlayCards(game, player, cards);
  }
}

class DaifugoRulesOptions {
  n5skip?: boolean;
  n7give?: boolean;
  n8clear?: boolean;
  n9reverse?: boolean;
  n10burn?: boolean;
  n11back?: boolean;
  joker?: boolean;
  tightSuits?: boolean;
  tightRank?: boolean;
  revolution?: boolean;
}

class DaifugoRules extends GameRules {
  private _n5skip: boolean;
  private _n7give: boolean;
  private _n8clear: boolean;
  private _n9reverse: boolean;
  private _n10burn: boolean;
  private _n11back: boolean;
  private _joker: boolean;
  private _tightSuits: boolean;
  private _tightRank: boolean;
  private _revolution: boolean;

  static fromObject(obj: any): DaifugoRules {
    return new DaifugoRules({
      n5skip: obj._n5skip,
      n7give: obj._n7give,
      n8clear: obj._n8clear,
      n9reverse: obj._n9reverse,
      n10burn: obj._n10burn,
      n11back: obj._n11back,
      joker: obj._joker,
      tightSuits: obj._tightSuits,
      tightRank: obj._tightRank,
      revolution: obj._revolution,
    });
  }

  static default(): DaifugoRules {
    return new DaifugoRules({
      n5skip: false,
      n7give: false,
      n8clear: false,
      n9reverse: false,
      n10burn: false,
      n11back: false,
      joker: false,
      tightSuits: true,
      tightRank: true,
      revolution: false,
    });
  }

  constructor(options: DaifugoRulesOptions) {
    super();
    this._n5skip = options.n5skip || false;
    this._n7give = options.n7give || false;
    this._n8clear = options.n8clear || false;
    this._n9reverse = options.n9reverse || false;
    this._n10burn = options.n10burn || false;
    this._n11back = options.n11back || false;
    this._joker = options.joker || false;
    this._tightSuits = options.tightSuits || false;
    this._tightRank = options.tightRank || false;
    this._revolution = options.revolution || false;
  }

  get n5skip(): boolean {
    return this._n5skip;
  }

  get n7give(): boolean {
    return this._n7give;
  }

  get n8clear(): boolean {
    return this._n8clear;
  }

  get n9reverse(): boolean {
    return this._n9reverse;
  }

  get n10burn(): boolean {
    return this._n10burn;
  }

  get n11back(): boolean {
    return this._n11back;
  }

  get joker(): boolean {
    return this._joker;
  }

  get tightSuits(): boolean {
    return this._tightSuits;
  }

  get tightRank(): boolean {
    return this._tightRank;
  }

  get revolution(): boolean {
    return this._revolution;
  }

  matchesTightSuits(table: Table, cards: number[]): boolean {
    const suits = table.tightSuits;
    var cs = cards.slice();
    for (const suit of suits) {
      var c = cs.find(card => Card.getSuit(card) === suit);
      if (!c) {
        c = cs.find(card => Card.getSuit(card) === 'JOKER');
      }
      if (!c) {
        console.error('Suit', suit, 'not found in cards', cs);
        return false;
      }
      cs = cs.filter(card => card !== c);
    }

    return true;
  }

  matchesTightRank(table: Table, cards: number[]): boolean {
    if (!table.tightRank) {
      return true;
    }

    const rank = table.currentRank;
    for (const card of cards) {
      if (Card.getSuit(card) === 'JOKER') {
        continue;
      }
      if (Card.getRank(card) !== rank + 1) {
        console.error('Card', card, `{${Card.getRank(card)}}`, 'does not match required rank', rank + 1);
        return false;
      }
    }
    return true;
  }


  isMoveLegal(game: Game, player: Player, cards: number[]): boolean {
    if (!super.isMoveLegal(game, player, cards)) {
      return false;
    }

    if (cards.length > 4) {
      console.error('Cannot play more than 4 cards');
      return false;
    }

    const rank = Card.getRank(cards.every(card => Card.getSuit(card) === 'JOKER') ? 52 : cards[0]);

    for (const card of cards) {
      if (Card.getRank(card) !== rank && Card.getSuit(card) !== 'JOKER') {
        console.error('All cards must have the same rank');
        console.error('cards:', cards, 'ranks:', cards.map(card => Card.getRank(card)), 'rank:', rank, 'table', game.table.cards, 'tableRank:', game.table.currentRank);
        return false;
      }
    }

    if (rank > game.table.currentRank) {
      if (game.table.backward && game.revolution % 2 === 0) {
        console.error('Cannot play higher rank if table is backward');
        return false;
      }
      if (!game.table.backward && game.revolution % 2 === 1) {
        console.error('Cannot play higher rank during revolution if table is not backward');
        return false;
      }
    }

    if (rank < game.table.currentRank) {
      if (!game.table.backward && game.revolution % 2 === 0) {
        console.error('Cannot play lower rank if table is not backward');
        return false;
      }
      if (game.table.backward && game.revolution % 2 === 1) {
        console.error('Cannot play lower rank during revolution if table is backward');
        return false;
      }
    }

    if (this.tightSuits && !this.matchesTightSuits(game.table, cards)) {
      return false;
    }

    if (this.tightRank && !this.matchesTightRank(game.table, cards)) {
      return false;
    }
    
    return true;
  }
}

export { GameRules, DaifugoRules };
export default DaifugoRules;