import { v4 as uuidv4 } from 'uuid';

interface PlayerOptions {
  name: string;
  uid?: string;
  playable?: boolean;
  score?: number;
  hand?: number[];
  selectedCards?: number[];
  isPlayersTurn?: boolean;
}


class Player {
  private _name: string;
  private _uid: string;
  private _playable: boolean;
  private _score: number;
  private _hand: number[];
  private _selectedCards: number[];
  private _isPlayersTurn: boolean;

  static fromObject(obj: any): Player {
    return new Player({
      name: obj._name,
      uid: obj._uid,
      playable: obj._playable,
      score: obj._score,
      hand: obj._hand,
      selectedCards: obj._selectedCards,
      isPlayersTurn: obj._isPlayersTurn,
    });
  }

  constructor(options: PlayerOptions) {
    this._name = options.name;
    this._uid = options.uid || uuidv4();
    this._playable = options.playable || false;
    this._score = options.score || 0;
    this._hand = options.hand || [];
    this._selectedCards = options.selectedCards || [];
    this._isPlayersTurn = options.isPlayersTurn || false;
  }

  get uid(): string {
    return this._uid;
  }

  get name(): string {
    return this._name;
  }

  get score(): number {
    return this._score;
  }

  set score(score: number) {
    this._score = score;
  }

  get hand(): number[] {
    return this._hand;
  }

  set hand(hand: number[]) {
    this._hand = hand;
  }

  get selectedCards(): number[] {
    return this._selectedCards;
  }

  selectCard(card: number): void {
    this._selectedCards.push(card);
  }

  deselectCard(card: number): void {
    this._selectedCards = this._selectedCards.filter(c => c !== card);
  }

  deselectAll(): void {
    this._selectedCards = [];
  }

  get playable(): boolean {
    return this._playable;
  }

  get isPlayersTurn(): boolean {
    return this._isPlayersTurn;
  }

  set isPlayersTurn(isPlayersTurn: boolean) {
    this._isPlayersTurn = isPlayersTurn;
  }
}

export default Player;