import React, { useEffect } from 'react';
import './App.css';

import { ReactElement } from 'react';
import useWindowDimensions from './hooks/windowDimension';

import SquaredGameTable from './GameTable';
import CreateOrJoinDialog from './components/Dialog';
import Game from 'shared/game_logic/game';

import socket from './socket';
import DaifugoRules from 'shared/game_logic/gamerules';

function App() {
  const { height, width } = useWindowDimensions();

  const [game, setGameState] = React.useState<Game | undefined>(undefined);

  useEffect(() => {
    socket.on('playerJoined', (username: string, game: object) => {
      setGameState(Game.fromObject(game));
    });

    socket.on('gameStart', (game: object) => {
      setGameState(Game.fromObject(game));
    });

    socket.on('gameUpdate', (game: object) => {
      setGameState(Game.fromObject(game));
    });

    return () => {
      socket.off('playerJoined');
      socket.off('gameStart');
      socket.off('gameUpdate');
    }
  }, []);

  return (
    <div className="App">
      <CreateOrJoinDialog open={!game} handleCreate={(username: string, gameRules: DaifugoRules) => {
        socket.emit('create', username, gameRules, (game: object) => {
          console.log(game);
          setGameState(Game.fromObject(game));
        });
      }} handleJoin={(username: string) => {
        socket.emit('join', username, (game: object) => {
          console.log(game);
          setGameState(Game.fromObject(game));
        });
      }} />

      <SquaredGameTable dimension={Math.min(height, width)} game={game} onMove={(cards: number[]) => {
        socket.emit('move', cards);
      }} />
    </div>
  );
}

export default App;
